import { render, staticRenderFns } from "./Attendance.vue?vue&type=template&id=36a8404e&scoped=true&"
import script from "./Attendance.vue?vue&type=script&lang=ts&"
export * from "./Attendance.vue?vue&type=script&lang=ts&"
import style0 from "./Attendance.vue?vue&type=style&index=0&id=36a8404e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36a8404e",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
installComponents(component, {VAlert,VAvatar,VBtn,VCol,VDataTable,VDatePicker,VIcon,VImg,VList,VListItem,VListItemGroup,VListItemTitle,VMenu,VRow,VSheet,VSlideGroup,VSlideItem})


/* hot reload */
if (module.hot) {
  var api = require("/Users/adriandaluz/Development/git/planbook-web-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('36a8404e')) {
      api.createRecord('36a8404e', component.options)
    } else {
      api.reload('36a8404e', component.options)
    }
    module.hot.accept("./Attendance.vue?vue&type=template&id=36a8404e&scoped=true&", function () {
      api.rerender('36a8404e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/Attendance.vue"
export default component.exports