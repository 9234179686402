var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    {
      ref: "attendanceRef",
      staticClass: "attendance",
      attrs: { color: "#ffffff" }
    },
    [
      _vm.$vuetify.breakpoint.smAndDown || _vm.localIsMenuOverlapping
        ? _c(
            "v-sheet",
            { staticClass: "mx-auto", attrs: { elevation: "0" } },
            [
              _c(
                "v-slide-group",
                {
                  staticClass: "py-2",
                  attrs: { "center-active": "" },
                  model: {
                    value: _vm.localSlideGroupModel,
                    callback: function($$v) {
                      _vm.localSlideGroupModel = $$v
                    },
                    expression: "localSlideGroupModel"
                  }
                },
                [
                  _c(
                    "v-slide-item",
                    { key: 0 },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            transition: "scroll-y-transition",
                            "offset-y": "",
                            "close-on-content-click": false
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "ml-2",
                                            attrs: {
                                              outlined: "",
                                              rounded: "",
                                              small: "",
                                              color: "info"
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" " + _vm._s(_vm.dateText) + " ")]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1380905790
                          ),
                          model: {
                            value: _vm.showDatePicker,
                            callback: function($$v) {
                              _vm.showDatePicker = $$v
                            },
                            expression: "showDatePicker"
                          }
                        },
                        [
                          _c("v-date-picker", {
                            ref: "datePicker",
                            attrs: { locale: _vm.getGlobalLanguage },
                            on: {
                              change: function($event) {
                                return _vm.refresh(false)
                              }
                            },
                            model: {
                              value: _vm.date,
                              callback: function($$v) {
                                _vm.date = $$v
                              },
                              expression: "date"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-slide-item",
                    { key: 1 },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            transition: "scroll-y-transition",
                            "offset-y": "",
                            "close-on-content-click": ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "ml-2",
                                            attrs: {
                                              outlined: "",
                                              color: _vm.lessonMenuColor,
                                              rounded: "",
                                              small: ""
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.lessonMenuLabel) +
                                            " "
                                        ),
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "ml-2",
                                            attrs: { small: "" }
                                          },
                                          [_vm._v("fal fa-chevron-down")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3447621060
                          )
                        },
                        [
                          _c(
                            "perfect-scrollbar",
                            {
                              staticStyle: {
                                "max-height": "calc(100vh - 300px)"
                              }
                            },
                            [
                              _c(
                                "v-list",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.isLessonsEmpty,
                                      expression: "!isLessonsEmpty"
                                    }
                                  ]
                                },
                                [
                                  _c(
                                    "v-list-item-group",
                                    {
                                      staticClass: "menu-list",
                                      attrs: {
                                        mandatory: "",
                                        "aria-label": _vm.$t(
                                          "lessonAttendanceLabel"
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.refresh(false)
                                        }
                                      },
                                      model: {
                                        value: _vm.selectedLessonIdx,
                                        callback: function($$v) {
                                          _vm.selectedLessonIdx = $$v
                                        },
                                        expression: "selectedLessonIdx"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            { staticClass: "px-2" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("allDayLabel"))
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._l(_vm.lessons, function(lesson) {
                                        return _c(
                                          "v-list-item",
                                          { key: lesson.subjectId },
                                          [
                                            _c(
                                              "v-list-item-title",
                                              {
                                                staticClass:
                                                  "drop-down-list py-2 px-4 rounded-lg",
                                                class: _vm.highContrastMode
                                                  ? "contrast-view"
                                                  : "",
                                                style: {
                                                  color: "white",
                                                  backgroundColor:
                                                    lesson.classColor
                                                }
                                              },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(lesson.className)
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.isLessonsEmpty
        ? _c(
            "div",
            [
              !_vm.$vuetify.breakpoint.smAndDown
                ? _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        {
                          class: _vm.controlsClass,
                          style: {
                            display: "inline-block",
                            "margin-left": _vm.isAttendanceClear
                              ? "260px !important"
                              : ""
                          }
                        },
                        [
                          !_vm.isAttendanceClear
                            ? _c(
                                "v-btn",
                                {
                                  class: _vm.clearAllButtonClass,
                                  attrs: { text: "", color: "primary" },
                                  on: { click: _vm.clearAttendance }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-3",
                                      attrs: { small: "" }
                                    },
                                    [_vm._v("fal fa-trash-can")]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("clearAllLabel", { text: "" })
                                      ) +
                                      " "
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-btn",
                            {
                              class: _vm.allPresentButtonClass,
                              attrs: { text: "", color: "primary" },
                              on: { click: _vm.setAllPresent }
                            },
                            [
                              _vm.isAllPresent
                                ? _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-3",
                                      attrs: { color: "green" }
                                    },
                                    [_vm._v("fal fa-square-check")]
                                  )
                                : _c("v-icon", { staticClass: "mr-3" }, [
                                    _vm._v("fal fa-square")
                                  ]),
                              _vm._v(" " + _vm._s(_vm.$t("presentLabel")) + " ")
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              class: _vm.allTardyAbsentButtonClass,
                              attrs: { text: "", color: "primary" },
                              on: { click: _vm.setAllTardy }
                            },
                            [
                              _vm.isAllTardy
                                ? _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-3",
                                      attrs: { color: "orange" }
                                    },
                                    [_vm._v("fal fa-square-check")]
                                  )
                                : _c("v-icon", { staticClass: "mr-3" }, [
                                    _vm._v("fal fa-square")
                                  ]),
                              _vm._v(" " + _vm._s(_vm.$t("tardyLabel")) + " ")
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              class: _vm.allTardyAbsentButtonClass,
                              attrs: { text: "", color: "primary" },
                              on: { click: _vm.setAllAbsent }
                            },
                            [
                              _vm.isAllAbsent
                                ? _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-3",
                                      attrs: { color: "red" }
                                    },
                                    [_vm._v("fal fa-square-check")]
                                  )
                                : _c("v-icon", { staticClass: "mr-3" }, [
                                    _vm._v("fal fa-square")
                                  ]),
                              _vm._v(" " + _vm._s(_vm.$t("absentLabel")) + " ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-data-table", {
                ref: "table",
                staticClass: "student-table",
                attrs: {
                  headers: _vm.studentHeaders,
                  items: _vm.students,
                  search: _vm.studentSearch,
                  height: _vm.tableHeight,
                  "mobile-breakpoint": "0",
                  "hide-default-footer": "",
                  "disable-pagination": "",
                  loading: _vm.localListLoading,
                  "loading-text": _vm.$t("listLoadingMsg"),
                  "sort-by": ["label"]
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.photo",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-avatar",
                            {
                              attrs: {
                                size: "2.5rem",
                                color: _vm.lessonMenuColor
                              }
                            },
                            [
                              _vm.hasPhoto(item)
                                ? _c("v-img", { attrs: { src: item.photoUrl } })
                                : _c("span", { staticClass: "white--text" }, [
                                    _vm._v(
                                      " " + _vm._s(_vm.getInitials(item)) + " "
                                    )
                                  ])
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "item.present",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "td",
                            [
                              _c(
                                "pb-btn",
                                {
                                  key: _vm.attendanceMapKey,
                                  staticClass: "border-padding",
                                  attrs: {
                                    icon: "",
                                    label: _vm.$t("presentLabel")
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.setPresent(item)
                                    }
                                  }
                                },
                                [
                                  _vm.isPresent(item)
                                    ? _c(
                                        "v-icon",
                                        {
                                          attrs: { large: "", color: "green" }
                                        },
                                        [_vm._v("fas fa-face-grin-wide")]
                                      )
                                    : _c("v-icon", { attrs: { large: "" } }, [
                                        _vm._v("fal fa-face-grin-wide")
                                      ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "item.tardy",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "td",
                            [
                              _c(
                                "pb-btn",
                                {
                                  key: _vm.attendanceMapKey,
                                  staticClass: "border-padding",
                                  attrs: {
                                    icon: "",
                                    label: _vm.$t("tardyLabel")
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.setTardy(item)
                                    }
                                  }
                                },
                                [
                                  _vm.isTardy(item)
                                    ? _c(
                                        "v-icon",
                                        {
                                          attrs: { large: "", color: "orange" }
                                        },
                                        [_vm._v("fas fa-face-meh")]
                                      )
                                    : _c("v-icon", { attrs: { large: "" } }, [
                                        _vm._v("fal fa-face-meh")
                                      ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "item.absent",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "td",
                            [
                              _c(
                                "pb-btn",
                                {
                                  key: _vm.attendanceMapKey,
                                  staticClass: "border-padding",
                                  attrs: {
                                    icon: "",
                                    label: _vm.$t("absentLabel")
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.setAbsent(item)
                                    }
                                  }
                                },
                                [
                                  _vm.isAbsent(item)
                                    ? _c(
                                        "v-icon",
                                        { attrs: { large: "", color: "red" } },
                                        [_vm._v("fas fa-face-frown-slight")]
                                      )
                                    : _c("v-icon", { attrs: { large: "" } }, [
                                        _vm._v("fal fa-face-frown-slight")
                                      ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1239877142
                )
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-alert",
        {
          staticClass: "attendance-updated-alert",
          attrs: {
            type: "success",
            value: _vm.showUpdateSuccess,
            transition: "fade-transition"
          }
        },
        [_vm._v(" " + _vm._s(_vm.$t("attendanceUpdatedMsg")) + " ")]
      ),
      _c(
        "v-alert",
        {
          staticClass: "attendance-updated-alert",
          attrs: {
            type: "warning",
            value: _vm.showNoStudentsAlert,
            transition: "fade-transition"
          }
        },
        [_vm._v(" " + _vm._s(_vm.$t("noStudentsMsg")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }