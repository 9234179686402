














































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import moment from 'moment-timezone';
import DateTimeUtils from '@/utils/date-time-utils';
import CommonUtils from '@/utils/common-utils';
import StudentUtils from '@/utils/student-utils';
import PageLifeCycleMixin from '@/mixins/page-lifecycle-mixin';
import TableResizeMixin from '@/mixins/table-resize-mixin';

const attendance = namespace('attendance');
const settings = namespace('settings');
const classes = namespace('classes');

@Component({
  mixins: [PageLifeCycleMixin, TableResizeMixin]
})
export default class Attendance extends Vue {
  tableHeight!: string;
  resizeTable!: () => void;
  isPickingDate = false;
  date = moment().format('YYYY-MM-DD');
  selectedLessonIdx = 0;
  allDayColor = 'primary';
  isAllPresent = false;
  isAllTardy = false;
  isAllAbsent = false;
  isAttendanceClear = true;
  showUpdateSuccess = false;
  showNoStudentsAlert = false;
  timeoutId = 0;
  attendanceMap = new Map<number, string>();
  attendanceMapKey = '';
  studentSearch = '';
  localSlideGroupModel = null;
  localIsMenuOverlapping = false;
  lastBrowserWidth = 0;
  showDatePicker = false;

  @attendance.Action
  loadLessonsByDate!: (params?: any) => Promise<any>;

  @attendance.Action
  loadStudents!: (params?: any) => Promise<any>;

  @attendance.Action
  addAttendance!: (params?: any) => Promise<any>;

  @attendance.Getter
  getLessons!: any[];

  @attendance.Getter
  getStudents!: any[];

  @settings.Getter
  getDateStyling!: any;

  @settings.Getter('getBrowserWidth')
  browserWidth!: number;

  @settings.Getter('getGlobalLanguage')
  globalLanguage!: any;

  @settings.Getter
  highContrastMode!: boolean;

  @attendance.Getter
  getListLoading!: boolean;

  @classes.Getter
  getClassesMap!: any;

  @Watch('browserWidth')
  onBrowserWidthChanged(val:any) {
    this.isMenuOverlapping(val);
  }

  get getGlobalLanguage() {
    return this.globalLanguage || 'en';
  }

  get controlsClass() {
    if (this.$vuetify.breakpoint.name === 'xs') {
      return 'mt-4';
    } else {
      return 'ml-16 mt-4';
    }
  }

  get searchCols() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xl': return 6;
      case 'lg': return 4;
      default: return 11;
    }
  }

  get searchColClass() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xl':
      case 'lg': return 'mr-6';
      case 'md': return 'ml-11';
      case 'sm': return 'ml-7';
      case 'xs': return 'ml-6';
    }
  }

  get clearAllButtonClass() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xl':
      case 'lg':
      case 'md': return 'ml-3 mr-16';
      case 'sm': return '';
      case 'xs': return 'ml-3';
    }
  }

  get allPresentButtonClass() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xl':
      case 'lg':
      case 'md': return 'ml-16';
      case 'sm': return 'ml-8';
      case 'xs': return '';
    }
  }

  get allTardyAbsentButtonClass() {
    if (this.$vuetify.breakpoint.name === 'xs' ||
      this.$vuetify.breakpoint.name === 'sm') {
      return '';
    } else {
      return 'ml-5'
    }
  }

  get studentHeaders() {
    let labelWidth = '';
    let presentTardyWidth = '';

    switch (this.$vuetify.breakpoint.name) {
      case 'xl':
      case 'lg':
      case 'md': {
        labelWidth = '16rem';
        presentTardyWidth = '9rem';
        break;
      }
      case 'sm': {
        labelWidth = '9rem';
        presentTardyWidth = '8rem';
        break;
      }
    }

    const headers = [
      { text: '', value: 'photo', sortable: false, width: '4%' },
      { text: this.$t('namesLabel'), value: 'label', width: labelWidth },
      { text: '', value: 'present', sortable: false, width: presentTardyWidth },
      { text: '', value: 'tardy', sortable: false, width: presentTardyWidth },
      { text: '', value: 'absent', sortable: false }
    ];

    return headers;
  }

  get dateFormat() {
    let format = 'MM/DD/YYYY';

    if (+this.getDateStyling.dateFormat === 2) {
      format = 'DD/MM/YYYY';
    }

    return format;
  }

  get lessons() {
    const classes = Object.values(this.getClassesMap);
    console.log('lessons info', this.getLessons)
    return this.getLessons.filter((l: any) => {
      const c: any = classes.find((c: any) => {
        return c.cId === l.subjectId
      });

      if (c !== undefined) {
        return !c.noStudents;
      } else {
        return false;
      }
    });
  }

  get students() {
    if (this.selectedLesson) {
      return this.getStudents.filter(student => student.inSubject === 'Y').map((student) => {
        student.label = this.getStudentLabel(student);
        return student;
      });
    } else if (!this.isLessonsEmpty) {
      return this.getStudents.map((student) => {
        student.label = this.getStudentLabel(student);
        return student;
      });
    } else {
      return [];
    }
  }

  get isLessonsEmpty() {
    return this.lessons.length === 0;
  }

  get selectedLesson() {
    if (this.selectedLessonIdx === 0) {
      return null;
    } else {
      return this.lessons[this.selectedLessonIdx - 1];
    }
  }

  get lessonMenuLabel() {
    if (this.selectedLesson) {
      return this.selectedLesson.className;
    } else if (this.isLessonsEmpty) {
      return this.$t('attendanceNoClassDayLabel');
    } else {
      return this.$t('allDayLabel');
    }
  }

  get lessonMenuColor() {
    if (this.selectedLesson && !this.highContrastMode) {
      return this.selectedLesson.classColor;
    } else {
      return this.allDayColor;
    }
  }

  get dateText() {
    return this.formatToShow(this.date);
  }

  get localListLoading() {
    return this.getListLoading;
  }

  set localListLoading(value: boolean) {
    this.$store.commit('attendance/setListLoading', value);
  }

  @Watch('showDatePicker')
  focusDatePicker() {
    if (this.showDatePicker) {
      this.$nextTick(() => {
        new Promise(resolve => setTimeout(resolve, 100)).then(() => {
          try {
            (this.$refs as any).datePicker.$el.querySelector('.v-date-picker-header__value').querySelector('button').focus()
          } catch (e) {
            console.log(this.$t('focusFailed'));
          }
        });
      });
    }
  }

  pickDate() {
    this.isPickingDate = true;
  }

  setAllPresent() {
    if (this.students.length === 0) {
      this.setNoStudentsTimeout();

      return;
    }

    this.isAllPresent = true;
    this.isAllTardy = false;
    this.isAllAbsent = false;

    for (const student of this.students) {
      this.attendanceMap.set(student.studentId, 'P');
    }

    this.isAttendanceClear = false;

    this.attendanceMapKey = 'All-P';

    this.updateAttendance();
  }

  setAllTardy() {
    if (this.students.length === 0) {
      this.setNoStudentsTimeout();

      return;
    }

    this.isAllPresent = false;
    this.isAllTardy = true;
    this.isAllAbsent = false;

    for (const student of this.students) {
      this.attendanceMap.set(student.studentId, 'T');
    }

    this.isAttendanceClear = false;

    this.attendanceMapKey = 'All-T';

    this.updateAttendance();
  }

  setAllAbsent() {
    if (this.students.length === 0) {
      this.setNoStudentsTimeout();

      return;
    }

    this.isAllPresent = false;
    this.isAllTardy = false;
    this.isAllAbsent = true;

    for (const student of this.students) {
      this.attendanceMap.set(student.studentId, 'A');
    }

    this.isAttendanceClear = false;

    this.attendanceMapKey = 'All-A';

    this.updateAttendance();
  }

  hasPhoto(student: any) {
    return CommonUtils.hasText(student.photoUrl);
  }

  get getInitials() {
    return StudentUtils.getStudentInitials;
  }

  getStudentLabel(student: any) {
    return StudentUtils.getStudentLabel(student);
  }

  isPresent(student: any) {
    return this.attendanceMap.get(student.studentId) === 'P';
  }

  isTardy(student: any) {
    return this.attendanceMap.get(student.studentId) === 'T';
  }

  isAbsent(student: any) {
    return this.attendanceMap.get(student.studentId) === 'A';
  }

  setPresent(student: any) {
    this.isAllPresent = false;
    this.isAllTardy = false;
    this.isAllAbsent = false;
    this.attendanceMap.set(student.studentId, 'P');
    this.isAttendanceClear = false;

    this.attendanceMapKey = student.studentId + '-P';

    this.updateAttendance();
  }

  setTardy(student: any) {
    this.isAllPresent = false;
    this.isAllTardy = false;
    this.isAllAbsent = false;
    this.attendanceMap.set(student.studentId, 'T');
    this.isAttendanceClear = false;

    this.attendanceMapKey = student.studentId + '-T';

    this.updateAttendance();
  }

  setAbsent(student: any) {
    this.isAllPresent = false;
    this.isAllTardy = false;
    this.isAllAbsent = false;
    this.attendanceMap.set(student.studentId, 'A');
    this.isAttendanceClear = false;

    this.attendanceMapKey = student.studentId + '-A';

    this.updateAttendance();
  }

  clearAttendance() {
    if (this.students.length === 0) {
      this.setNoStudentsTimeout();

      return;
    }

    this.isAllPresent = false;
    this.isAllTardy = false;
    this.isAllAbsent = false;
    this.attendanceMap.clear();
    this.isAttendanceClear = true;

    this.attendanceMapKey = 'All-Clear';

    this.updateAttendance();
  }

  private setNoStudentsTimeout() {
    clearTimeout(this.timeoutId);
    this.showNoStudentsAlert = true;
    this.timeoutId = setTimeout(() => {
      this.showNoStudentsAlert = false;
    }, 2000);
  }

  private formatToShow(date: string) {
    return DateTimeUtils.formatToShow(moment(date).format('MM/DD/YYYY'));
  }

  private formatToSave(date: string) {
    return DateTimeUtils.formatToSave(moment(date).format(this.dateFormat));
  }

  refresh(initialLoad:boolean) {
    this.attendanceMap.clear();
    this.isPickingDate = false;
    this.isAllPresent = false;
    this.isAllTardy = false;
    this.isAllAbsent = false;
    this.attendanceMapKey = 'All-Clear';
    this.isAttendanceClear = true;

    initialLoad ? CommonUtils.showLoading() : this.localListLoading = true;

    this.loadLessonsByDate({
      attendanceDate: this.formatToSave(this.date)
    }).then(() => {
      return this.loadStudents({
        subjectIds: this.selectedLesson ? this.selectedLesson.subjectId : 0,
        attendanceDate: this.formatToSave(this.date),
        extraClass: 0
      }).then(() => {
        for (const student of this.students) {
          if (student.attendanceCode && student.attendanceCode !== '') {
            this.attendanceMap.set(student.studentId, student.attendanceCode);
            this.isAttendanceClear = false;
          }
        }

        this.attendanceMapKey = 'Load';
        this.localListLoading = false;
      });
    }).finally(() => {
      if (initialLoad) this.resizeTable();
      CommonUtils.hideLoading();
    });
  }

  updateAttendance() {
    this.localListLoading = true;

    const request = new URLSearchParams();

    for (const student of this.students) {
      if (this.attendanceMap.has(student.studentId)) {
        request.append('students', JSON.stringify({
          id: student.studentId,
          code: this.attendanceMap.get(student.studentId)
        }));
      } else {
        request.append('students', JSON.stringify({ id: student.studentId }));
      }
    }

    request.append('attendanceDate', this.formatToSave(this.date));
    request.append('subjects', JSON.stringify({
      id: this.selectedLesson ? this.selectedLesson.subjectId : 0,
      extraClass: 0
    }));

    this.addAttendance(request).then(() => {
      this.localListLoading = false;

      clearTimeout(this.timeoutId);
      this.showUpdateSuccess = true;
      this.timeoutId = setTimeout(() => {
        this.showUpdateSuccess = false;
      }, 2000);
    });
  }

  initialize() {
    this.refresh(true);
  }

  created() {
    this.initialize();
  }

  isMenuOverlapping(browserWidth?:number) {
    if (this.lastBrowserWidth !== 0 && (browserWidth || 0) < this.lastBrowserWidth) {
      this.localIsMenuOverlapping = true;
      return;
    }
    if ((browserWidth || 0) > this.lastBrowserWidth) {
      this.localIsMenuOverlapping = false;
      this.lastBrowserWidth = 0;
      return;
    }
    setTimeout(() => {
      const toolbarTitle:any = document.getElementById('toolbarTitle');
      const titleRect = toolbarTitle.getBoundingClientRect();
      const attendanceLessonMenu:any = document.getElementById('attendanceLessonMenu');
      if (attendanceLessonMenu) {
        const attendanceLessonMenuRect = attendanceLessonMenu.getBoundingClientRect();
        if (
          titleRect.top < attendanceLessonMenuRect.bottom &&
            titleRect.bottom > attendanceLessonMenuRect.top &&
            titleRect.left < attendanceLessonMenuRect.right &&
            titleRect.right > attendanceLessonMenuRect.left
        ) {
          this.lastBrowserWidth = this.browserWidth;
          this.localIsMenuOverlapping = true;
          return true;
        }
        this.localIsMenuOverlapping = false;
        return false;
      } else {
        this.localIsMenuOverlapping = false;
        return false;
      }
    }, 50);
    this.localIsMenuOverlapping = false;
    return false;
  }
}
